// extracted by mini-css-extract-plugin
export var addButton = "styles-module--addButton--ab611";
export var colorIcon = "styles-module--colorIcon--b2fdc";
export var container = "styles-module--container--69499";
export var deleteButton = "styles-module--deleteButton--c3c10";
export var hexText = "styles-module--hexText--2bdb3";
export var inputGroup = "styles-module--inputGroup--d310f";
export var inputGroupHeader = "styles-module--inputGroupHeader--4ef40";
export var inputLabel = "styles-module--inputLabel--c9405";
export var objectEditor = "styles-module--objectEditor--68960";
export var saveWidget = "styles-module--saveWidget--61db1";